<template>
  <div class="container-scroller">
    <Navbar />
    <div class="container-fluid page-body-wrapper">
      <Sidebar />
      <div class="main-panel">
        <div class="content-wrapper">
          <div class="row">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-3">
                      <p class="card-title mb-0">Bitácora</p>
                    </div>
                    <div class="col-md-9">
                      <button
                        @click="exportExcel"
                        class="btn btn-primary float-right"
                      >
                        <span class="icon-download"></span> Exportar Excel
                      </button>
                    </div>
                  </div>

                  <br>
                  <div class="row">
                      <div class="col-md-3">
                          <input type="date" class="form-control" v-model="fechaInicio"/>
                      </div>
                      <div class="col-md-3 mb-2">
                          <input type="date" class="form-control" v-model="fechaFin"/>
                      </div>
                      <div class="col-md-3">
                          <button type="button" class="btn btn-secondary" @click="consultarBitacora" :disabled="fechaInicio && fechaFin ? false : true">Consultar</button>
                      </div>
                  </div>
                  <br/>

                  <div style="height: 500px;overflow: scroll;overflow-x:auto">
                    <table class="table table-striped  table-borderless">
                      <thead class="table-dark" style="position: sticky; top: 0;">
                        <tr>
                          <th>Fecha de consulta</th>
                          <th>Parámetro de consulta</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in bitacora" :key="item.id">
                          <td>{{ item.fecha }}</td>
                          <td>{{ item.parametroConsulta }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br/>

                  <div style="width: 200px;" v-if="bitacora.length">
                    <p class="card-title mb-2">Ranking (Top Ten)</p>
                    <table class="table table-striped  table-borderless">
                      <thead class="table-dark" style="position: sticky; top: 0;">
                        <tr>
                          <th>Parámetro de consulta</th>
                          <th>Número de consultas</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in ranking" :key="item.id">
                          <td>{{ item.codigo }}</td>
                          <td>{{ item.total }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { saveExcel } from "@progress/kendo-vue-excel-export";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";

export default {
  components: { Navbar, Sidebar },
  data() {
    return {
      fechaInicio: '',
      fechaFin: '',
      ranking: []
    };
  },
  created(){
    if(this.$store.state.auth.rol !== 'ROLE_SADMIN'){
      this.$store.dispatch("auth/logout");
      return;
    }
    this.$store.commit("bitacora/setBitacora", []);
  },
  methods: {
    exportExcel() {
      saveExcel({
        data: this.bitacora,
        fileName: "data",
        columns: [
          {field: 'fecha', title: 'Fecha de consulta'},
          {field: 'parametroConsulta', title: 'Parámetro de consulta'},
        ],
      });
    },
    async consultarBitacora() {
      try {
        this.ranking = [];
        this.loaderSpinner();
        const data = {
          fechaInicio: this.fechaInicio,
          fechaFin: this.fechaFin,
        };
        await this.$store.dispatch("bitacora/consultarBitacora", data);
        this.contarDuplicados();
      } catch (error) {
        console.log(error);
      }finally{
        this.loader.hide();
      }
    },
    contarDuplicados(){
      const resultado = this.bitacora.reduce((prev, cur) => ((prev[cur.parametroConsulta] = prev[cur.parametroConsulta] + 1 || 1), prev), {});
      let arrayResultado = [];
      for (const property in resultado) {
       arrayResultado.push({codigo: property, total: resultado[property]});
      }
      const resultadoOrdenado = arrayResultado.sort(function(a, b){return b.total - a.total});
      this.ranking = resultadoOrdenado.slice(0,10);
    },
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
  },
  computed: {
    bitacora() {
      return this.$store.state.bitacora.bitacora;
    },
  },
};
</script>
<style>
.btn-rechazar {
  margin-left: 10px;
}
</style>
