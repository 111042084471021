import {createRouter, createWebHistory} from 'vue-router'
import Login from '../views/Login.vue'
import Lead from '../views/leads/Lead'
import Consulta from '../views/consulta/Consulta'
import ConsultaRechazados from '../views/consulta/ConsultaRechazados'
import Bitacora from '../views/bitacora/Bitacora'
import ResultadosEncuesta from '../views/encuesta/ResultadosEncuesta'

const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login
    },
    {
        path: '/lead',
        name: 'Lead',
        component: Lead,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/consulta',
        name: 'Consulta',
        component: Consulta,
        meta: {
            requiresAuth: true
        }

    },
    {
        path: '/consulta-rechazados',
        name: 'ConsultaRechazados',
        component: ConsultaRechazados,
        meta: {
            requiresAuth: true
        }

    },
    {
        path: '/bitacora',
        name: 'Bitacora',
        component: Bitacora,
        meta: {
            requiresAuth: true
        }

    },
    {
        path: '/encuesta',
        name: 'Encuesta',
        component: ResultadosEncuesta,
        meta: {
            requiresAuth: true
        }

    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    const authUser = window.localStorage.getItem("_token_chatbot") ? true : false;
    if (to.meta.requiresAuth && !authUser) next({name: "Login"});
    else next();
});

export default router
