import axios from "axios";

const state = {
  clienteLeads: [],
  clienteLeadsTotal : [],
  totalPaginas: null,
};

const getters = {
  getClienteLeads: (state) => state.clienteLeads,
  getClienteLeadsTotal: (state) => state.clienteLeadsTotal,
  getTotalPaginas: (state) => state.totalPaginas,
};

const mutations = {
  setClienteLeads: (state, data) => {
    state.clienteLeads = data;
  },
  setClienteLeadsTotal: (state, data) => {
    state.clienteLeadsTotal = data;
  },
  setTotalPaginas: (state, data) => {
    state.totalPaginas = data;
  },
};

const actions = {
  async consultarClienteLeads({ commit }, estado) {
    try {
      const resp = await axios.get("/v1/admin/leads/" + estado);
      commit("setClienteLeadsTotal", {
        clienteLeads: resp.data.result,
      });
      return resp;
    } catch (error) {
      console.log(error);
      return error;
    }
  },

  async procesarClienteLeads({ commit }, data) {
    try {
      if (data?.metodo === "rechazar") {
        await axios.post("/v1/admin/leads/" + data.metodo, {
          idClientes: data.idClientes,
          motivo: data?.motivo,
        });
      } else {
        await axios.post("/v1/admin/leads/" + data.metodo, {
          idClientes: data.idClientes,
        });
      }
      const clientesLeadsFiltrados = data.clienteLeads.filter(
        (item) => data.idClientes.indexOf(item.id) === -1
      );
      commit("setClienteLeads", {
        clienteLeads: clientesLeadsFiltrados,
      });

      return clientesLeadsFiltrados;
    } catch (error) {
      console.log(error);
      return error;
    }
  },
  async consultarTotalPaginas({ commit }, data) {
    const { estado } = data;
    try {
      const resp = await axios.get(`/v1/admin/leads/${estado}/total`);
      commit("setTotalPaginas", resp?.data?.result?.totalPaginas);
      return resp;
    } catch (error) {
      console.log(error);
      return error;
    }
  },
  async consultarClienteLeadsXPagina({ commit }, data) {
    const { estado, pagina } = data;
    try {
      const resp = await axios.get(`/v1/admin/leads/${estado}/${pagina}/pagina`);
      commit("setClienteLeads", {
        clienteLeads: resp.data.result,
      });
      return resp;
    } catch (error) {
      console.log(error);
      return error;
    }
  },
  async cargarArchivo({ state }, data) {
    console.log(state.totalPaginas)
    try {
      const headers = { 'Content-Type': 'multipart/form-data' };
      await axios.post(`/v1/admin/upload`, data, { headers });
      return "success"
    } catch (error) {
      return "error";
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
