import axios from "axios";

const state = {
  motivos: [],
};

const getters = {
  getMotivos: (state) => state.motivos,
};

const mutations = {
  setMotivos: (state, data) => {
    state.motivos = data;
  },
};

const actions = {
  async consultarMotivos({ commit }) {
    try {
      const resp = await axios.get("/v1/admin/motivos");
      commit("setMotivos", resp.data.result);
      return resp;
    } catch (error) {
      console.log(error);
      return error;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
