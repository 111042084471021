<template>
  <div
    class="modal fade"
    id="modalRechazar"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalRechazarLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalRechazarLabel">Advertencia</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          Se realizará el proceso de <strong>{{ proceso }}</strong> para los
          clientes lead seleccionados. Está seguro?
          <div class="form-group mt-3">
            <select v-model="motivo" class="form-control" required>
              <option disabled value="">Seleccione un motivo</option>
              <option v-for="motivo in motivos" :key="motivo.id" :value="motivo.descripcion">
                {{ motivo.descripcion }}
              </option>
            </select>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Cancelar
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="procesar"
            data-dismiss="modal"
            :disabled="motivo ? false : true"
          >
            Aceptar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    method: { type: Function },
    proceso: String,
  },
  data() {
    return {
      motivo: "",
    };
  },
  mounted(){
    this.cargarMotivos();
  },
  methods:{
    async cargarMotivos (){
      try {
        await this.$store.dispatch("motivo/consultarMotivos");
      } catch (error) {
        console.log(error);
      }
    },
    procesar(){
      const motivo = this.motivo;
      this.method(motivo);
    }
  },
  computed: {
    motivos() {
      return this.$store.getters["motivo/getMotivos"]
        ? this.$store.getters["motivo/getMotivos"]
        : [];
    },
  },
};
</script>
