<template>
  <div class="container-scroller">
    <div class="container-fluid page-body-wrapper full-page-wrapper">
      <div class="content-wrapper d-flex align-items-center auth px-0">
        <div class="row w-100 mx-0">
          <div class="col-lg-4 mx-auto">
            <div class="auth-form-light text-left py-5 px-4 px-sm-5">
              <div class="brand-logo">
                <img src="../assets/images/logo-cerveceria-nacional.png" alt="logo">
              </div>
              <h4>Bienvenido! </h4>
              <h6 class="font-weight-light">Ingrese sus credenciales.</h6>
              <form @submit.prevent="login" class="pt-3">
                <div class="form-group">
                  <input type="text" v-model="formData.usuario" class="form-control form-control-lg" id="exampleInputEmail1" placeholder="Username">
                </div>
                <div class="form-group">
                  <input type="password" v-model="formData.clave" class="form-control form-control-lg" id="exampleInputPassword1" placeholder="Password">
                </div>
                <p class="text-danger text-center" v-if="error">Revise el usuario o contraseña</p>
                <div class="mt-3">

                  <button class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" type="submit">LOGIN</button>
                </div>
                <div class="my-2 d-flex justify-content-between align-items-center">
                  <div class="form-check">

                  </div>
                  <a href="#" class="auth-link text-black">Olvidó su clave?</a>
                </div>


              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- content-wrapper ends -->
    </div>
    <!-- page-body-wrapper ends -->
  </div>

</template>
<script>

export default {
  name: 'Home',
  components: {},
  data() {
    return {
        formData : {},
        error: false
    }
  },
  methods: {
    async login() {
      this.error = false;
      try {
        const resp = await this.$store.dispatch("auth/login", this.formData);
        const { error } = resp;
        if(error) {
          this.error = true;
          this.$router.push({ name: "Login" });
          return;
        }

        if(resp?.data?.rol === 'ROLE_ADMIN'){
          this.$router.push("/lead");
          return;
        }

        if(resp?.data?.rol === 'ROLE_USER'){
          this.$router.push("/consulta-rechazados");
          return;
        }

        if(resp?.data?.rol === 'ROLE_SADMIN'){
          this.$router.push("/lead");
          return;
        }

        if(resp?.data?.rol === 'ROLE_VISUALIZADOR'){
          this.$router.push("/lead");
          return;
        }

      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
