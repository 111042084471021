import { createStore } from 'vuex'
import auth from "./modules/auth";
import lead from './modules/lead'
import motivo from './modules/motivo'
import bitacora from './modules/bitacora'
import encuesta from './modules/encuesta'

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    lead,
    motivo,
    bitacora,
    encuesta
  }
})
