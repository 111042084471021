<template>
  <router-view/>
</template>
<style>
@import "assets/css/feather.css";
@import "assets/css/themify-icons.css";
@import "assets/css/vendor.bundle.base.css";
@import "assets/css/dataTables.bootstrap4.css";
@import "assets/css/select.dataTables.min.css";
@import "assets/css/style.css";
</style>
