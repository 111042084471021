<template>
  <div class="container-scroller">
    <Navbar/>
    <div class="container-fluid page-body-wrapper">
      <Sidebar/>
      <div class="main-panel">
        <div class="content-wrapper">
          <div class="row">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-2">
                      <p class="card-title mb-0">Consulta</p>
                    </div>
                    <div class="col-md-10">
                      <button
                          @click="exportExcel"
                          class="btn btn-primary float-right"
                      >
                        <span class="icon-download"></span> Exportar Excel
                      </button>

                      <button style="margin-right: 10px"
                              @click="exportTodo"
                              class="btn btn-primary float-right"
                      >
                        <span class="icon-download"></span> Todo
                      </button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-2">
                      <div class=" form-check-inline">
                        <input
                            class="form-check-input"
                            type="radio"
                            id="aprobados"
                            value="APROBADO"
                            v-model="estado"
                            @change="onChangeEstado($event)"
                        />
                        <label class="form-check-label" for="aprobados">
                          Aprobados
                        </label>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-check-inline">
                        <input
                            class="form-check-input"
                            type="radio"
                            id="rechazados"
                            value="RECHAZADO"
                            v-model="estado"
                            @change="onChangeEstado($event)"
                        />
                        <label class="form-check-label" for="rechazados">
                          Rechazados
                        </label>
                      </div>
                    </div>
                  </div>

                  <br>
                  <div style="height: 500px;overflow: scroll;overflow-x:auto">
                    <table class="table table-striped  table-borderless">
                      <thead class="table-dark" style="position: sticky; top: 0;">
                      <tr>
                        <th>Id Cliente Modelo</th>
                        <th>Tipo Identificación</th>
                        <th>Identificación</th>
                        <th>Nombre Completo</th>
                        <th>Nombre Comercial</th>
                        <th>Teléfono Móvil 1</th>
                        <th>Teléfono Móvil 2</th>
                        <th>Teléfono Móvil 3</th>
                        <th>Dirección</th>
                        <th>Referencia de la Dirección</th>
                        <th>Barrio</th>
                        <th>Cod Provincia</th>
                        <th>Provincia</th>
                        <th>Cod Municipio</th>
                        <th>Municipio</th>
                        <th>Correo Electrónico</th>
                        <th>Fecha de Nacimiento</th>
                        <th>Estrato</th>
                        <th>Tipo Negocio</th>
                        <th>Subcanal</th>
                        <th>Subcanal Global</th>
                        <th>Latitud</th>
                        <th>Longitud</th>
                        <th>Cliente Cascaron</th>
                        <th>Cliente Existe/Bloqueado</th>
                        <th>Fecha Creación</th>
                        <th>Respuesta Encuesta</th>
                        <th>Cod Sector</th>
                        <th>BDR</th>
                        <th>Referido</th>
                        <th>Motivo</th>
                        <th>Fecha Rechazo</th>
                        <th>Tipo Referido</th>
                        <th>Tipo Asesoramiento</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="item in listaClienteLead" :key="item.id">
                        <td>{{ item.id }}</td>
                        <td>{{ item.tipoIdentificacion }}</td>
                        <td>{{ item.identificacion }}</td>
                        <td>{{ item.nombres }}</td>
                        <td>{{ item.nombreComercial }}</td>
                        <td>{{ item.numeroCelular }}</td>
                        <td>{{ item.numeroCelularAdicional }}</td>
                        <td></td>
                        <td>{{ item.direccion }}</td>
                        <td>{{ item.referencia }}</td>
                        <td>{{ item.barrio }}</td>
                        <td>{{ item.codigoProvincia }}</td>
                        <td>{{ item.provincia }}</td>
                        <td>{{ item.codigoMunicipio }}</td>
                        <td>{{ item.municipio }}</td>
                        <td>{{ item.correoElectronico }}</td>
                        <td></td>
                        <td>{{ item.estrato }}</td>
                        <td>{{ item.tipoNegocio }}</td>
                        <td>{{ item.subcanal }}</td>
                        <td></td>
                        <td>{{ item.latitud }}</td>
                        <td>{{ item.longitud }}</td>
                        <td>{{ item.clienteCascaron }}</td>
                        <td></td>
                        <td>{{ item.fecha }}</td>
                        <td></td>
                        <td>{{ item.codigoSector }}</td>
                        <td>{{ item.bdr }}</td>
                        <td>{{ item.referido }}</td>
                        <td>{{ item.motivo }}</td>
                        <td>{{ item.fechaRechazo }}</td>
                        <td>{{ item.tipoReferido }}</td>
                        <td>{{ item.tipoAsesoramiento }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <br/>
                  <div class="d-flex justify-content-center">
                    <nav aria-label="Page navigation example" v-if="paginas">
                      <ul class="pagination">
                        <li class="page-item" :disabled="pagina===1">
                          <a class="page-link" @click="setPreviewPage">
                            <span aria-hidden="true">&laquo;</span>
                          </a>
                        </li>
                        <li
                            v-for="page in paginas"
                            :key="page"
                            class="page-item"
                            :class="{'active': pagina === page}"
                        >
                          <a class="page-link" @click="setPage(page)">{{
                              page
                            }}</a>
                        </li>
                        <li class="page-item" :disabled="pagina===paginas">
                          <a class="page-link" @click="setNextPage">
                            <span aria-hidden="true">&raquo;</span>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {saveExcel} from "@progress/kendo-vue-excel-export";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";

export default {
  components: {Navbar, Sidebar},
  data() {
    return {
      idSeleccionadosProxy: [],
      idSeleccionados: [],
      metodo: null,
      mostrar: false,
      estado: "APROBADO",
      pagina: 1,
      estadoActual: 'APROBADO',
      estadoAnterior: 'APROBADO',
      columns: [
        {field: 'id', title: 'ID Cliente Modelo'},
        {field: 'tipoIdentificacion', title: 'Tipo de Identificación'},
        {field: 'identificacion', title: 'Identificacion'},
        {field: 'nombres', title: 'Nombre Completo'},
        {field: 'nombreComercial', title: 'Nombre Comercial del Establecimiento'},
        {field: 'numeroCelular', title: 'Teléfono Móvil 1'},
        {field: 'numeroCelularAdicional', title: 'Teléfono Móvil 2'},
        {field: '-', title: 'Teléfono Móvil 3'},
        {field: 'direccion', title: 'Dirección'},
        {field: 'referencia', title: 'Referencia de la Dirección'},
        {field: 'barrio', title: 'Barrio'},
        {field: 'codigoProvincia', title: 'Código de Provincia'},
        {field: 'provincia', title: 'Provincia'},
        {field: 'codigoMunicipio', title: 'Código Municipio'},
        {field: 'municipio', title: 'Municipio'},
        {field: 'correoElectronico', title: 'Correo Electrónico'},
        {field: '-', title: 'Fecha de Nacimiento'},
        {field: 'estrato', title: 'Estrato'},
        {field: 'tipoNegocio', title: 'Tipo de Negocio'},
        {field: 'subcanal', title: 'Subcanal'},
        {field: '-', title: 'Subcanal Global'},
        {field: 'latitud', title: 'Latitud'},
        {field: 'longitud', title: 'Longitud'},
        {field: 'clienteCascaron', title: 'Cliente Cascarón'},
        {field: '-', title: 'Cliente Existe/Bloqueado'},
        {field: 'fecha', title: 'Fecha de Creación'},
        {field: '-', title: 'Respuesta Encuesta'},
        {field: 'codigoSector', title: 'Código Sector'},
        {field: 'bdr', title: 'BDR'},
        {field: 'referido', title: 'Referido'},
        {field: "motivo", title: "Motivo"},
        {field: "fechaRechazo", title: "Fecha Rechazo"},
        {field: "tipoReferido", title: "Tipo Referido"},
        {field: "tipoAsesoramiento", title: "Tipo Asesoramiento"},
      ],
    };
  },
  created() {
    if (this.$store.state.auth.rol !== 'ROLE_ADMIN' && this.$store.state.auth.rol !== 'ROLE_SADMIN' && this.$store.state.auth.rol !== 'ROLE_VISUALIZADOR') {
      this.$store.dispatch("auth/logout");
      return;
    }
  },
  mounted() {
    this.consultarCLienteLeads(1);
  },
  methods: {

    async exportTodo() {

      await this.consultarCLienteLeadsTotal()

      await saveExcel({
        data: this.listaClienteLeadTotal,
        fileName: "data_total",
        columns: this.columns,
      });
    },

    exportExcel() {
      saveExcel({
        data: this.listaClienteLead,
        fileName: "data",
        columns: this.columns,
      });

    },
    async consultarCLienteLeads(pagina) {
      try {
        this.loaderSpinner();
        const data = {
          estado: this.estado,
          pagina: pagina || 1,
        };
        await this.$store.dispatch("lead/consultarClienteLeadsXPagina", data);
        await this.$store.dispatch("lead/consultarTotalPaginas", data);
        this.pagina = pagina;
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },

    async consultarCLienteLeadsTotal() {
      try {
        this.loaderSpinner();
        await this.$store.dispatch("lead/consultarClienteLeads", this.estado)
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
    onChangeEstado(event) {
      const estado = event?.target?.value;
      this.estado = estado;
      this.estadoActual = estado;
      this.consultarCLienteLeads(1);
    },
    setPage(page) {
      this.pagina = page;
    },
    setNextPage() {
      if (this.pagina < this.totalPaginas) {
        this.pagina = this.pagina + 1;
      }
    },
    setPreviewPage() {
      if (this.pagina > 1) {
        this.pagina = this.pagina - 1;
      }
    },
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
  },
  computed: {
    listaClienteLead() {
      return this.$store.state.lead.clienteLeads.clienteLeads;
    },
    listaClienteLeadTotal() {
      return this.$store.state.lead.clienteLeadsTotal.clienteLeads;
    },
    totalPaginas() {
      return this.$store.state.lead.totalPaginas;
    },
    paginas() {
      let numeroPaginas = 10;
      numeroPaginas = Math.min(numeroPaginas, this.totalPaginas);
      let first = this.pagina - Math.floor(numeroPaginas / 2);
      first = Math.max(first, 1);
      first = Math.min(first, this.totalPaginas - numeroPaginas + 1);
      return [...Array(numeroPaginas)].map((k, i) => i + first);
    }
  },
  watch: {
    pagina(newPage, oldPage) {
      if (!newPage) return;
      if (this.estadoActual !== this.estadoAnterior) {
        this.estadoAnterior = this.estado;
        return;
      }
      if (newPage !== oldPage) {
        this.consultarCLienteLeads(newPage);
      }
    }
  },
};
</script>
<style>
.btn-rechazar {
  margin-left: 10px;
}
</style>
