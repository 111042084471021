<template>
  <div class="container-scroller">
    <Navbar />
    <div class="container-fluid page-body-wrapper">
      <Sidebar />
      <div class="main-panel">
        <div class="content-wrapper">
          <div class="row">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-3">
                      <p class="card-title mb-0">Resultados de la encuesta</p>
                    </div>
                    <div class="col-md-9">
                      <button
                        @click="exportExcel"
                        class="btn btn-primary float-right"
                      >
                        <span class="icon-download"></span> Exportar Excel
                      </button>
                    </div>
                  </div>

                  <br />
                  <div class="row">
                    <div class="col-md-3">
                      <input
                        type="date"
                        class="form-control"
                        v-model="fechaInicio"
                      />
                    </div>
                    <div class="col-md-3 mb-2">
                      <input
                        type="date"
                        class="form-control"
                        v-model="fechaFin"
                      />
                    </div>
                    <div class="col-md-3">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        @click="consultarResultadosEncuesta"
                        :disabled="fechaInicio && fechaFin ? false : true"
                      >
                        Consultar
                      </button>
                    </div>
                  </div>
                  <br />

                  <div class="row">
                    <div
                      class="card col-lg-12 col-sm-12 mb-4"
                      v-for="item in detalleResultadoEncuesta"
                      :key="item.id"
                    >
                      <h5 class="card-header">
                        <div class="d-flex justify-content-between">
                          <div>Código de cliente: {{ item.codigoCliente }}</div>
                          <div>{{item.fecha}}  -  {{item.hora}}</div>
                        </div>
                        
                      </h5>
                      <div class="card-body">
                        <h6 class="card-subtitle">¿Nuestro vendedor lo visitó?</h6>
                        <p class="card-title"> {{item.visita}}</p>
                        <h6 class="card-subtitle">¿Qué tan satisfecho estás con la atención del asesor que te visita?</h6>
                        <h5 class="card-title">{{ item.nivelSatisfaccion }}</h5>
                        <h6 class="card-subtitle">{{ item.pregunta }}</h6>
                        <p
                          class="card-text m-2"
                          v-for="(respuesta, index) in item.respuestas"
                          :key="index"
                        >
                          {{ respuesta.respuesta }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <!-- <div
                    style="height: 500px; overflow: scroll; overflow-x: auto"
                  >
                    <table class="table table-striped table-borderless">
                      <thead
                        class="table-dark"
                        style="position: sticky; top: 0"
                      >
                        <tr>
                          <th>Identificador encuesta</th>
                          <th>Código de cliente</th>
                          <th>Nivel de satisfacción</th>
                          <th>Pregunta</th>
                          <th>Respuesta</th>
                          <th>Comentarios</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in resultadoEncuesta" :key="item.id">
                          <td>{{ item.encuesta.id }}</td>
                          <td>{{ item.encuesta.codigoCliente }}</td>
                          <td>{{ item.encuesta.nivelSatisfaccion }}</td>
                          <td>{{ item.encuesta.pregunta }}</td>
                          <td>{{ item.respuesta }}</td>
                          <td>{{ item.encuesta.comentarios }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div> -->
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { saveExcel } from "@progress/kendo-vue-excel-export";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";

export default {
  components: { Navbar, Sidebar },
  data() {
    return {
      fechaInicio: "",
      fechaFin: "",
    };
  },
  created() {
    if (this.$store.state.auth.rol !== "ROLE_SADMIN") {
      this.$store.dispatch("auth/logout");
      return;
    }
    this.$store.commit("encuesta/setResultadoEncuesta", []);
    this.$store.commit("encuesta/setDetalleResultadoEncuesta", []);
  },
  methods: {
    exportExcel() {
      saveExcel({
        data: this.detalleResultadoEncuesta,
        fileName: "data",
        columns: [
          { field: "id", title: "Identificador encuesta" },
          { field: "fecha", title: "Fecha encuesta" },
          { field: "hora", title: "Hora encuesta" },
          { field: "codigoCliente", title: "Código de cliente" },
          { field: "visita", title: "¿Nuestro vendedor lo visitó?" },
          {
            field: "nivelSatisfaccion",
            title: "¿Qué tan satisfecho estás con la atención del asesor que te visita?",
          },
          { field: "respuestasString", title: "¿Qué es lo que consideras que debemos mejorar?" },
          { field: "comentarios", title: "Comentarios" },
        ],
      });
    },
    async consultarResultadosEncuesta() {
      try {
        this.ranking = [];
        this.loaderSpinner();
        const data = {
          fechaInicio: this.fechaInicio,
          fechaFin: this.fechaFin,
        };
        await this.$store.dispatch(
          "encuesta/consultarDetalleResultadoEncuesta",
          data
        );
        await this.$store.dispatch("encuesta/consultarResultadoEncuesta", data);
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
  },
  computed: {
    resultadoEncuesta() {
      return this.$store.state.encuesta.resultadoEncuesta;
    },
    detalleResultadoEncuesta() {
      return this.$store.state.encuesta.detalleResultadoEncuesta;
    },
  },
};
</script>
<style>
.btn-rechazar {
  margin-left: 10px;
}
</style>
