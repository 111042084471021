<template>
  <nav class="sidebar sidebar-offcanvas" id="sidebar">
    <ul class="nav">
      <li class="nav-item" v-if="rol === 'ROLE_ADMIN'">
        <router-link class="nav-link" href="#" :to="{name:'Lead'}">
          <i class="icon-head menu-icon"></i>
          <span class="menu-title">Clientes Lead</span>
        </router-link>
      </li>

      <li class="nav-item" v-if="rol === 'ROLE_ADMIN'">
        <router-link class="nav-link" :to="{name:'Consulta'}">
          <i class="icon-search menu-icon"></i>
          <span class="menu-title">Consulta</span>
        </router-link>
      </li>

      <li class="nav-item" v-if="rol === 'ROLE_USER'">
        <router-link class="nav-link" :to="{name:'ConsultaRechazados'}">
          <i class="icon-search menu-icon"></i>
          <span class="menu-title">Consulta Rechazados</span>
        </router-link>
      </li>

      <li class="nav-item" v-if="rol === 'ROLE_SADMIN'">
        <router-link class="nav-link" href="#" :to="{name:'Lead'}">
          <i class="icon-head menu-icon"></i>
          <span class="menu-title">Clientes Lead</span>
        </router-link>
      </li>

      <li class="nav-item" v-if="rol === 'ROLE_SADMIN'">
        <router-link class="nav-link" :to="{name:'Consulta'}">
          <i class="icon-search menu-icon"></i>
          <span class="menu-title">Consulta</span>
        </router-link>
      </li>

      <li class="nav-item" v-if="rol === 'ROLE_SADMIN'">
        <router-link class="nav-link" :to="{name:'Bitacora'}">
          <i class="icon-search menu-icon"></i>
          <span class="menu-title">Bitácora</span>
        </router-link>
      </li>

      <li class="nav-item" v-if="rol === 'ROLE_SADMIN'">
        <router-link class="nav-link" :to="{name:'Encuesta'}">
          <i class="icon-clipboard menu-icon"></i>
          <span class="menu-title">Encuesta</span>
        </router-link>
      </li>


      <li class="nav-item" v-if="rol === 'ROLE_VISUALIZADOR'">
        <router-link class="nav-link" href="#" :to="{name:'Lead'}">
          <i class="icon-head menu-icon"></i>
          <span class="menu-title">Clientes Lead</span>
        </router-link>
      </li>

      <li class="nav-item" v-if="rol === 'ROLE_VISUALIZADOR'">
        <router-link class="nav-link" :to="{name:'Consulta'}">
          <i class="icon-search menu-icon"></i>
          <span class="menu-title">Consulta</span>
        </router-link>
      </li>

    </ul>
  </nav>
</template>
<script>
export default {
  methods: {
    async logout () {
      await this.$store.dispatch('auth/logout');
    },
  },
  computed: {
    rol() {
      if(!this?.$store?.state?.auth?.rol){
        this.logout();
        return;
      }
      return this.$store.state.auth.rol;
    },
  },
}
</script>

