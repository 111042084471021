import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import jwt_decode from "jwt-decode";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

createApp(App)
  .use(Loading, {
    canCancel: false,
    loader: "spinner",
    color: "#ED3123",
  })
  .use(store)
  .use(router)
  .mount("#app");


axios.defaults.baseURL = "https://leadscncontrol.com/chatbot/api";
//axios.defaults.baseURL = "http://144.126.220.233:8081/api";

axios.interceptors.request.use(
  (config) => {
    const token = window.localStorage.getItem("_token_chatbot");
    config.headers.Authorization = `Bearer ${token}`;
    if (token != null) {
      if (jwt_decode(token).exp >= Date.now().valueOf() / 1000) {
        config.headers.Authorization = `Bearer ${token}`;
      } else {
        store.dispatch("auth/logout");
        router.replace("/");
      }
    } else {
      store.dispatch("auth/logout");
      router.replace("/");
    }
    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);
