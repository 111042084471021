import axios from "axios";

const state = {
  bitacora: [],
};

const getters = {
  getBitacora: (state) => state.bitacora,
};

const mutations = {
  setBitacora: (state, data) => {
    state.bitacora = data;
  },
};

const actions = {
  async consultarBitacora({ commit }, data) {
    try {
      const resp = await axios.get(`/v1/admin/fecha-inicio/${data.fechaInicio}/fecha-fin/${data.fechaFin}/landing-bitacora`);
      commit("setBitacora", resp?.data?.result);
      return resp;
    } catch (error) {
      console.log(error);
      commit("setBitacora", []);
      return error;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
